.switch {
  width: 100%;
  height: 56px;
  display: flex;
}

.switch__box {
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.switch__invisible-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.switch__pseudo-item {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  border-radius: 12px;
  background: var(--inactive-btn-bg);
  box-sizing: border-box;
  padding: 2px;
  cursor: pointer;
}

.switch__btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 10px;
  background: var(--inactive-btn-bg);
  transition: background-color 0.2s ease-in-out;
}

.switch__btn_active {
  background: var(--active-btn-bg);
}

.switch__check-icon {
  width: 0;
  height: 12px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

.switch__btn_active .switch__check-icon {
  width: 12px;
  opacity: 1;
  visibility: visible;
}

.switch__check-icon-fill {
  fill: var(--contrast-text-color);
}

.switch__btn-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  transition: color 0.2s ease-in-out;
}

.switch__btn_active .switch__btn-text {
  color: var(--contrast-text-color);
}
