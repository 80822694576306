.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: auto;
  gap: 16px;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 60px;
}

.main_type_start {
  gap: 0;
}

.main__content {
  width: 100%;
  max-width: 600px;
  flex: auto;
  position: relative;
  z-index: 1;
}

.main__content_type_start {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 16px 0;
}

.main__start-box {
  width: 390px;
  height: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 62px;
  position: relative;
}

.main__start-logo {
  width: 239px;
  height: 320px;
}

.main__start-logo-fill {
  fill: var(--contrast-text-color);
}

.main__start-text {
  color: var(--contrast-text-color);
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  position: relative;
  bottom: -8px;
}

.main__start-bg-shadow {
  width: 500%;
  aspect-ratio: 1/2;
  transform: rotate(45deg);
  box-shadow: -20px -16px 204px var(--accent-bg-shadow) inset;
  position: absolute;
  top: -444%;
  left: -500%;
  z-index: 0;
}

.main__footer {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 0 32px;
  z-index: 1;
}

.main__agreement {
  color: var(--contrast-text-color);
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.33;
  padding-bottom: 22px;
}

.main__agreement-link {
  text-decoration-line: underline;
}

.main__error-msg {
  color: var(--error-color);
  text-align: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.17;
}

.main__btn {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 17px 0;
}

.main__btn_disabled {
  pointer-events: none;
  user-select: none;
}

.main__btn-text {
  color: var(--contrast-text-color);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
}

.main__top-bg {
  width: 318px;
  height: 318px;
  position: absolute;
  top: -2px;
  left: -24px;
}

.main__bottom-bg {
  width: 550px;
  height: 550px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.main__triangle {
  width: 100%;
  height: 100%;
}

.main__triangle-fill {
  fill: var(--neutral-bg);
}
