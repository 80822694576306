.not-found-page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 42px;
    background-color: var(--website-background);
}

.not-found-page__title {
    font-size: 142px;
    font-weight: bold;
}

.not-found-page__link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: normal;
    width: 100%;
    max-width: 200px;
    aspect-ratio: 3/1;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    background: var(--accent-bg);
    box-shadow: 0px 14px 40px 0px var(--box-shadow);
    color: var(--contrast-text-color);
}