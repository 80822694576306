.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: var(--website-background);
  transition: background-color 0.2s ease-in-out;
}

.app__color_contrast {
  background-color: var(--accent-bg);
}
