@import url(./assets/fonts/SFProText/stylesheet.css);

body {
  margin: 0;
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--website-background);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote {
  padding: 0;
  margin: 0;
  color: var(--text-color);
  white-space: pre-line;
  font-style: normal;
}

a {
  font-style: normal;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  font-family: 'SF Pro Text', sans-serif;
  font-style: normal;
  color: var(--text-color);
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

input {
  color: var(--text-color);
  font-family: 'SF Pro Text', sans-serif;
  font-style: normal;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: transparent;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: 'SF Pro Text', sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--text-color) !important;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px rgba(0, 0, 0, 0);
  background-clip: content-box !important;
  position: relative;
  z-index: 0;
}

:root {
  --website-background: #ECEEF2;
  --text-color: #151431;
  --contrast-text-color: #FFFFFF;
  --container-bg: #FFFFFF;
  --active-btn-bg: #151431;
  --inactive-btn-bg: #CDD2DF;
  --box-shadow: rgba(152, 162, 187, 0.78);
  --secondary-box-shadow: rgba(197, 201, 213, 0.45);
  --error-color: #FF4A4A;
  --focus-color: #3686FF;
  --accent-bg: #B1001B;
  --accent-bg-shadow: rgba(74, 13, 13, 0.8);
  --neutral-bg: #E2E6ED;
}
