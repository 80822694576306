.custom-input {
  width: 100%;
  position: relative;
}

.custom-input__input-box {
  width: 100%;
  position: relative;
  background: var(--container-bg);
  border-radius: 12px;
}

.custom-input__label {
  max-width: calc(100% - 32px);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  transform-origin: top left;
  transform: translate(16px, 21px) scale(1);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}

.custom-input__label_focused {
  max-width: calc(115% - 32px);
  transform: translate(16px, 10px) scale(0.85);
}

.custom-input__input {
  width: 100%;
  height: 56px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid var(--container-bg);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 26px 16px 10px;
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 1;
}

.custom-input__input_type_error {
  border-color: var(--error-color);
}

.custom-input__input_focused {
  border-color: var(--focus-color);
}

.custom-input__input[type='date']::-webkit-calendar-picker-indicator {
  width: 24px;
  height: 24px;
  position: relative;
  right: -3px;
  bottom: 8px;
  opacity: 0;
}

.custom-input__input[type='date']::-webkit-datetime-edit {
  opacity: 0;
}

.custom-input__label_focused
  + .custom-input__input[type='date']::-webkit-datetime-edit {
  opacity: 1;
}

.custom-input__input-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 0;
}

.custom-input__input-icon-stroke {
  stroke: var(--focus-color);
}

.custom-input__input-icon-fill {
  fill: var(--focus-color);
}

.custom-input__error-box {
  margin-left: 16px;
}

.custom-input__error {
  display: grid;
  grid-template-columns: 12px 1fr;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.17;
}

.custom-input__error-icon {
  width: 12px;
  height: 13px;
}

.custom-input__error-icon-fill {
  fill: var(--error-color);
}
