.auth {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  box-sizing: border-box;
  padding: 40px 32px 0;
}

.auth__logo {
  width: 74px;
  height: 100px;
}

.auth__logo-fill {
  fill: var(--text-color);
}

.auth__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
