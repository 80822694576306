.success {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 52px 16px 0;
}

.success__title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.17;
}

.success__subtitle {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  padding-top: 12px;
}

.success__card {
  width: 100%;
  aspect-ratio: 179/114;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  background: var(--accent-bg);
  box-shadow: 0px 14px 40px 0px var(--box-shadow);
  margin-top: 48px;
}

.success__card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}

.success__card-logo {
  width: 27px;
  height: 84px;
  position: absolute;
  left: 16px;
  z-index: 1;
}

.success__card-logo-fill {
  fill: var(--contrast-text-color);
}

.success__wallets {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  padding: 0 16px;
  margin-top: 48px;
}

.success__wallet-btn {
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  display: flex;
  align-items: center;
  /*gap: 24px;*/
  /*border-radius: 12px;*/
  /*background: var(--container-bg);*/
  /*box-shadow: 0px 4px 15px 0px var(--secondary-box-shadow);*/
  box-sizing: border-box;
  /*padding: 2px;*/
}

.success__wallet-icon {
  width: 200px;

    margin: 0 auto;
}

.success__wallet-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
}
